import React, { useRef } from 'react';
import Image from "react-bootstrap/Image";
import Typography from "@material-ui/core/Typography";

import background from "./backgroundVariate_clipped.jpg";
import Button from "react-bootstrap/Button";
import Detail from "./Detail";
import About from "./About";
import Banner from "./Banner";
import "./Home.css";


const BannerWithRef = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <Banner {...props} />
  </div>
));

const Home = () => {
  const bannerRef = useRef(null);

  const scrollToBanner = () => {
    if (bannerRef.current) {
      bannerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (

    <div id="sec-home" style={{ paddingTop: "7vh" }}>
      <Image src={background} fluid style={{ width: "auto" }} />
      <div className="section">
        <Typography variant="h3" className="mainTitle">
          Advanced Weather Simulations <br></br>
          for <span className="boldUp">Renewable Energy</span>
        </Typography>

        <Typography
          variant="h6"
          className="mainSubTitle"
          style={{ lineHeight: "1.2", maxWidth: "75%" }}
        >
          variate.energy enables a weather-centric risk assessment of
          renewable energy projects, evaluation of portfolios, and simulations
          of entire systems.
        </Typography>
        <Button
          className="button"
          variant="outline-secondary"
          style={{ margin: "10px 20px", padding: "10px 25px" }}

          onClick={scrollToBanner}
        >
          CONTACT US
        </Button>
      </div>
      <About />
      <Detail />
      <BannerWithRef ref={bannerRef} />
    </div>
  );
}

export default Home;
