import React from "react";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Typography from "@material-ui/core/Typography";
import logo1 from "./img/iconfinder_statistics-data-analysis-diagrams_5640912.svg";
import logo2 from "./img/iconfinder_button-green_energy-wind_turbine-energy_saving-solar_panel_5640979.svg";
import logo3 from "./img/iconfinder_contract-signature-writing-document_5644420.svg";

import "./Home.css";

function About() {
  return (
    <div className="section">
      <CardDeck style={{ padding: "10px 20px" }}>
        <Card>
          <Card.Img variant="top" src={logo1} style={{ maxHeight: "30vh" }} />
          <Card.Body>
            <Card.Title>
              <Typography component={"span"} variant="h6">
                Assess long-term variability in energy yield over a project’s
                entire lifetime.
              </Typography>
            </Card.Title>
            <Card.Text>
              <Typography
                component={"span"}
                variant="subtitle1"
                style={{ fontWeight: 300 }}
              >
                We simulate and quantify the impact of next decade's weather on
                your investment to give you the best decision-basis to maximize
                future returns. Our risk assessments look at the timeframe that
                matters to help you make the most of your renewable energy
                assets.
              </Typography>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Img variant="top" src={logo2} style={{ maxHeight: "30vh" }} />
          <Card.Body>
            <Card.Title>
              <Typography component={"span"} variant="h6">
                Analyse projects across multiple technologies and locations.
              </Typography>
            </Card.Title>
            <Card.Text>
              <Typography
                component={"span"}
                variant="subtitle1"
                style={{ fontWeight: 300 }}
              >
                Assess interdependencies between wind and solar assets to
                identify and mitigate risks, as well as optimize your portfolio.
                Analyse the interdependencies between energy production and
                external factors, such as energy demand and market prices, in up
                to sub-hourly resolution.
              </Typography>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Img variant="top" src={logo3} style={{ maxHeight: "30vh" }} />
          <Card.Body>
            <Card.Title>
              <Typography component={"span"} variant="h6">
                Evaluate financing structures and identify financial risks.
              </Typography>
            </Card.Title>
            <Card.Text>
              <Typography
                component={"span"}
                variant="subtitle1"
                style={{ fontWeight: 300 }}
              >
                Our robust risk assessment support optimization of financial
                contracts through reliable risk metrics. By quantifying future
                risks we ensure that your product can be priced competitively.
              </Typography>
            </Card.Text>
          </Card.Body>
        </Card>
      </CardDeck>
    </div>
  );
}

export default About;
