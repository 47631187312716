import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "./Home.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function Imprint() {
  const classes = useStyles();

  return (
    <div
      className="container-box section"
      id="sec-contact "
      style={{ paddingTop: "12vh", paddingBottom: "12vh" }}
    >
      <div className={classes.root}>
        <Grid container spacing={2} style={{ width: "98%", margin: "auto" }}>
          <Grid item xs={12} sm={12}>
            <Paper
              className={classes.paper}
              elevation={0}
              spacing={0}
              style={{ paddingBottom: 0, backgroundColor: "inherit" }}
            >
              <Typography
                variant="h4"
                align="left"
                style={{ color: "black", fontWeight: "500" }}
              >
                Imprint
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper} variant={"outlined"}>
              <Typography component={"span"} variant="subtitle2">
                Angaben gemäß § 5 TMG
              </Typography>
              <br></br>

              <Typography component={"span"} variant="body2">
                ReinhardtHuang GmbH
                <br /> Waitzstraße 24
                <br /> 10629 Berlin
              </Typography>
              <br />
              <br></br>

              <Typography component={"span"} variant="subtitle2">
                Vertretungsberechtigte Geschäftsführer
              </Typography>
              <br></br>

              <Typography component={"span"} variant="body2">
                Charlotte Huang, Joachim Reinhardt
              </Typography>
              <br />
              <br></br>

              <Typography component={"span"} variant="subtitle2">
                Kontakt
              </Typography>
              <br></br>

              <Typography component={"span"} variant="body2">
                Telefon: +49 30 65214182
                <br />
                E-Mail: info@variate.energy
                <br /> Website: https://variate.energy
              </Typography>
              <br />
              <br></br>

              <Typography component={"span"} variant="subtitle2">
                Registereintrag
              </Typography>
              <br></br>

              <Typography component={"span"} variant="body2">
                Eintragung im Registergericht: Amtsgericht Charlottenburg
                <br />
                Registernummer: HRB221945
                <br /> Umsatzsteuer-Identifikationsnummer gemäß §27a
                Umsatzsteuergesetz: DE335859664.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper} variant={"outlined"}>
              <Typography component={"span"} variant="subtitle2">
                Haftungsausschluss
              </Typography>
              <br></br>

              <Typography component={"span"} variant="body2">
                Haftung für Inhalte Die Inhalte unserer Seiten wurden mit
                größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit
                und Aktualität der Inhalte können wir jedoch keine Gewähr
                übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für
                eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                gespeicherte fremde Informationen zu überwachen oder nach
                Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
                hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
                ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
                werden wir diese Inhalte umgehend entfernen.{" "}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper} variant={"outlined"}>
              <Typography component={"span"} variant="subtitle2">
                Haftungsausschluss
              </Typography>
              <br></br>
              <Typography component={"span"} variant="body2">
                Haftung für Links Unser Angebot enthält Links zu externen
                Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
                Deshalb können wir für diese fremden Inhalte auch keine Gewähr
                übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
                jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
                verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
                mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren
                zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
                konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
                Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                Links umgehend entfernen. Impressum vom Impressum Generator der
                Kanzlei Hasselbach, Rechtsanwälte für Arbeitsrecht und
                Familienrecht (angepasst).
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Imprint;
