import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Nav } from "react-bootstrap";
import Home from "./Home";

import { Route, Switch } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import Imprint from "./Imprint";
import Privacy from "./Privacy";

import "./Home.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 0.1,
      padding: 0,
      margin: 0,
    }}
  />
);

function Footer() {
  const classes = useStyles();

  return (
    <>
      <Switch>
        <Route exact path="/Imprint" component={Imprint} />
        <Route exact path="/Privacy" component={Privacy} />
        <Route exact path="/Home" component={Home} />
      </Switch>

      <div className={classes.root}>
        <ColoredLine color="darkgray" />
        <Grid container spacing={2} style={{ width: "98%", margin: "auto" }}>
          <Grid item xs={12} sm={3}>
            <Paper
              className={classes.paper}
              elevation={0}
              style={{ backgroundColor: "inherit" }}
            >
              <Typography component={"span"} variant="body2" align="justify">
                ReinhardtHuang GmbH<br></br>
                Waitzstraße 24<br></br>
                10629 Berlin<br></br>
                Germany<br></br>
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper
              className={classes.paper}
              elevation={0}
              style={{ backgroundColor: "inherit" }}
            >
              <Typography component={"span"} variant="body2" align="justify">
                <strong>Contact</strong>
                <br></br>
                <a
                  href="mailto:info@variate.energy"
                  style={{ fontWeight: "inherit", margin: "0" }}
                >
                  info@variate.energy
                </a>
                <br></br>
                <span style={{ textAlign: "left" }}>+49 30 65214182</span>
                <br></br>
                <a
                  href="https://www.linkedin.com/showcase/variate.energy"
                  style={{
                    fontWeight: "inherit",
                    margin: "0",
                    wordBreak: "break-all",
                  }}
                >
                  linkedin.com/showcase/variate.energy
                </a>
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper
              className={classes.paper}
              elevation={0}
              style={{ backgroundColor: "inherit" }}
            >
              <Typography component={"span"} variant="body2" align="justify">
                <strong>Services</strong>
                <br></br>
                Risk Assessment<br></br>
                Financial Assessment<br></br>
                Portfolio Assessment<br></br>
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper
              className={classes.paper}
              elevation={0}
              style={{ backgroundColor: "inherit" }}
            >
              <LinkContainer to="/Home" style={{ margin: 0, padding: 0 }}>
                <Nav.Link>
                  <Typography
                    component={"span"}
                    variant="body2"
                    align="justify"
                  >
                    Homepage
                  </Typography>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/Imprint" style={{ margin: 0, padding: 0 }}>
                <Nav.Link>
                  <Typography
                    component={"span"}
                    variant="body2"
                    align="justify"
                  >
                    Imprint
                  </Typography>
                </Nav.Link>
              </LinkContainer>

              <LinkContainer to="/Privacy" style={{ margin: 0, padding: 0 }}>
                <Nav.Link>
                  <Typography
                    component={"span"}
                    variant="body2"
                    align="justify"
                    margin={0}
                    padding={0}
                  >
                    Privacy
                  </Typography>
                </Nav.Link>
              </LinkContainer>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Footer;
