import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "./Home.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function Privacy() {
  const classes = useStyles();

  return (
    <div
      className="container-box section"
      id="sec-contact "
      style={{ paddingTop: "12vh" }}
    >
      <div className={classes.root}>
        <Grid container spacing={2} style={{ width: "98%", margin: "auto" }}>
          <Grid item xs={12} sm={12}>
            <Paper
              className={classes.paper}
              elevation={0}
              spacing={0}
              style={{ paddingBottom: 0, backgroundColor: "inherit" }}
            >
              <Typography
                variant="h4"
                align="left"
                style={{ color: "black", fontWeight: "500" }}
              >
                Privacy
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper} variant={"outlined"}>
              <Typography component={"span"} variant="body2">
                Personal data (usually referred to just as "data" below) will
                only be processed by us to the extent necessary and for the
                purpose of providing a functional and user-friendly website,
                including its contents, and the services offered there. Per Art.
                4 No. 1 of Regulation (EU) 2016/679, i.e. the General Data
                Protection Regulation (hereinafter referred to as the "GDPR"),
                "processing" refers to any operation or set of operations such
                as collection, recording, organization, structuring, storage,
                adaptation, alteration, retrieval, consultation, use, disclosure
                by transmission, dissemination, or otherwise making available,
                alignment, or combination, restriction, erasure, or destruction
                performed on personal data, whether by automated means or not.
                The following privacy policy is intended to inform you in
                particular about the type, scope, purpose, duration, and legal
                basis for the processing of such data either under our own
                control or in conjunction with others. We also inform you below
                about the third-party components we use to optimize our website
                and improve the user experience which may result in said third
                parties also processing data they collect and control.
              </Typography>
              <br></br>
              <br></br>

              <Typography variant="subtitle2">
                1. Information about us as controllers of your data
              </Typography>
              <br></br>
              <Typography component={"span"} variant="body2">
                The party responsible for this website (the "controller") for
                purposes of data protection law is: <br />
                <br />
                ReinhardtHuang GmbH <br />
                Waitzstraße 24 <br />
                10629 Berlin <br />
                Germany <br />
                <br />
                Telephone: +49 30 65214182 <br /> Email: info@variate.energy
                <br />
                Website: https://variate.energy
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper} variant={"outlined"}>
              <Typography variant="subtitle2">
                2. The rights of users and data subjects
              </Typography>
              <br></br>
              <Typography component={"span"} variant="body2">
                With regard to the data processing to be described in more
                detail below, users and data subjects have the right to
                confirmation of whether data concerning them is being processed,
                information about the data being processed, further information
                about the nature of the data processing, and copies of the data
                (cf. also Art. 15 GDPR); to correct or complete incorrect or
                incomplete data (cf. also Art. 16 GDPR); to the immediate
                deletion of data concerning them (cf. also Art. 17 DSGVO), or,
                alternatively, if further processing is necessary as stipulated
                in Art. 17 Para. 3 GDPR, to restrict said processing per Art. 18
                GDPR; to receive copies of the data concerning them and/or
                provided by them and to have the same transmitted to other
                providers/controllers (cf. also Art. 20 GDPR); to file
                complaints with the supervisory authority if they believe that
                data concerning them is being processed by the controller in
                breach of data protection provisions (see also Art. 77 GDPR). In
                addition, the controller is obliged to inform all recipients to
                whom it discloses data of any such corrections, deletions, or
                restrictions placed on processing the same per Art. 16, 17 Para.
                1, 18 GDPR. However, this obligation does not apply if such
                notification is impossible or involves a disproportionate
                effort. Nevertheless, users have a right to information about
                these recipients.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper} variant={"outlined"}>
              <Typography variant="subtitle2">
                3. Information about data processing
              </Typography>
              <br></br>

              <Typography component={"span"} variant="body2">
                Your data processed when using our website will be deleted or
                blocked as soon as the purpose for its storage ceases to apply,
                provided the deletion of the same is not in breach of any
                statutory storage obligations or unless otherwise stipulated
                below.
                <br />
                <br />
                <Typography variant="subtitle2">Simple Analytics</Typography>
                To get critical information about the behavior of our visitors,
                we use Simple Analytics. This analytics software gives us
                insight about our visitors only in general, but not about
                individuals per se, as it does not track visitors and does not
                store any personal identifiable information. Go to their
                documentation to find out what Simple Analytics collects (and
                most importantly what they don't).
                <br />
                <br />
                <img
                  src="https://simpleanalyticsbadges.com/variate.energy"
                  alt="Simple Analytics 100% Privacy Friendly Website Badge"
                  loading="lazy"
                  referrerPolicy="no-referrer"
                  crossOrigin="anonymous"
                />
                <br />
                <br />
                <Typography variant="subtitle2">Google Fonts </Typography>
                Google Fonts Our website uses Google Fonts to display external
                fonts. This is a service provided by Google Ireland Limited,
                Gordon House, Barrow Street, Dublin 4, Irland (hereinafter:
                Google). To enable the display of certain fonts on our website,
                a connection to the Google server in the USA is established
                whenever our website is accessed. The legal basis is Art. 6
                Para. 1 lit. f) GDPR. Our legitimate interest lies in the
                optimization and economic operation of our site. When you access
                our site, a connection to Google is established from which
                Google can identify the site from which your request has been
                sent and to which IP address the fonts are being transmitted for
                display. Google offers detailed information at
                https://adssettings.google.com/authenticated and
                https://policies.google.com/privacy.
                <br />
                <br />
                <Typography variant="subtitle2">LinkedIn </Typography>
                We maintain an online presence on LinkedIn to present our
                company and our services and to communicate with
                customers/prospects. LinkedIn is a service of LinkedIn Ireland
                Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Irland,
                a subsidiary of LinkedIn Corporation, 1000 W. Maude Avenue,
                Sunnyvale, CA 94085, USA. We would like to point out that this
                might cause user data to be processed outside the European
                Union, particularly in the United States. This may increase
                risks for users that, for example, may make subsequent access to
                the user data more difficult. We also do not have access to this
                user data. Access is only available to LinkedIn. The LinkedIn
                privacy policy can be found here:
                https://www.linkedin.com/legal/privacy-policy
                <br />
                <br />
                <Typography variant="subtitle2">Contact </Typography>
                If you contact us via email or the contact form, the data you
                provide will be used for the purpose of processing your request.
                We must have this data in order to process and answer your
                inquiry; otherwise we will not be able to answer it in full or
                at all.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Privacy;
