import React from "react";
import { Navbar } from "react-bootstrap";
import logo from "./variate-log-hq.png";
import Code from "./Code";
import ScrollToTop from "./ScrollToTop";
import { Route, Switch, Redirect } from "react-router-dom";
import "./Home.css";

const Navigation = () => {
  return (
    <>
      <Navbar
        fixed="top"
        expand="sm"
        bg="light"
        variant="light"
        position="sticky"
      >
        <Navbar.Brand href="/">
          <img
            src={logo}
            width="auto"
            height="40"
            className="d-inline-block align-top"
            alt="variate.energy"
          />
        </Navbar.Brand>
      </Navbar>
      <ScrollToTop />
      <Switch>
        {/* <Route path="/code" component={Code} /> */}
        <Route exact path="/">
          <Redirect to="/Home" />
        </Route>
      </Switch>
    </>
  );
};

export default Navigation;
